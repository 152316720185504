import * as React from 'react';
import { Input, Select, List, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {selectAvailableBalances} from '../../balances/balancesSlice';
import { useAppSelector } from '../../../app/hooks';
import { balanceOperationType } from '../constants';
import { Trans } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';
import cloneDeep from 'lodash/cloneDeep';

function defaultBalanceOperation(fieldFragment:FieldFragment) {
  return {
    id: uniqueId('NEW'),
    balance: { id : null },
    operation:  undefined,
    fieldFragment: { id: fieldFragment.id },
  };
}

interface Props {
  onChange: Function;
  customField: FieldFragment;
};

interface RenderItemProps {
  value: BalanceOperation;
  balanceOptions: Balance[];
  operationOptions: string[];
  onRemove: Function;
  onAdd: React.MouseEventHandler;
  showAdd: boolean;
  onChange: Function;
};

const RenderItem: React.FC<RenderItemProps> = (props: RenderItemProps) => (
  <List.Item>
    <Input.Group compact>
      <Select
        allowClear={true}
        placeholder="Select a balance"
        defaultValue={props.value.balance.id}
        options={props.balanceOptions.map(item => ({ label: item.name.en, value: item.id }))}
        onChange={value => props.onChange(props.value, 'balance', { id: value })}
        dropdownMatchSelectWidth={false}
      />
      <Select
        allowClear={true}
        placeholder="Select an operation"
        defaultValue={props.value.operation}
        options={props.operationOptions.map(item => ({ label: <Trans i18nKey={item}/>, value: item })) }
        onChange={value => props.onChange(props.value, 'operation', value)}
        dropdownMatchSelectWidth={false}
      />
    </Input.Group>
    {props.showAdd && <Button size='small' style={{marginRight: 4}} shape="circle" icon={<PlusOutlined />} onClick={props.onAdd} /> }
    <Button size='small' shape='circle' icon={<MinusOutlined />} onClick={(evt) => props.onRemove(evt, props.value) } />
  </List.Item>
)

export const BalanceOperation: React.FC<Props> = (props: Props) => {
  const balances = useAppSelector(selectAvailableBalances);
  const [localeBalanceOperations, setLocaleBalanceOperations] = React.useState<BalanceOperation[]|null>(null);
  const { onChange } = props;

  React.useEffect(() => {
    if (localeBalanceOperations === null && props.customField && props.customField.balanceOperations) {
      if (props.customField.balanceOperations.length === 0 ) {
        setLocaleBalanceOperations([defaultBalanceOperation(props.customField)]);
      } else {
        setLocaleBalanceOperations(cloneDeep(props.customField.balanceOperations.filter(item => item.balance)));
      }
    }
  }, [props.customField, localeBalanceOperations]);

  // CHANGE
  const localeOnChange = React.useCallback((balanceOperation:BalanceOperation, key:keyof BalanceOperation, value: string) => {
    if (localeBalanceOperations === null) { return; }
    setLocaleBalanceOperations(localeBalanceOperations.map(item => {
      if (item.id === balanceOperation.id) {
        item[key] = value;
      }
      return item;
    }));
    onChange(props.customField.id, localeBalanceOperations);
  }, [localeBalanceOperations, props.customField, onChange]);

  // ADD
  const addItem = React.useCallback((evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    if (localeBalanceOperations === null) { return; }
    setLocaleBalanceOperations([...localeBalanceOperations, defaultBalanceOperation(props.customField)]);
  }, [localeBalanceOperations, props.customField]);

  // REMOVE
  const removeItem = React.useCallback((evt: React.MouseEvent<HTMLAnchorElement>, value:BalanceOperation) => {
    evt.preventDefault();
    if (localeBalanceOperations === null) { return; }
    const newValue = localeBalanceOperations.filter(item => item.id !== value.id);
    if (newValue.length === 0) {
      newValue.push(defaultBalanceOperation(props.customField));
    }
    onChange(props.customField.id, newValue);
    return setLocaleBalanceOperations(newValue);
  }, [localeBalanceOperations, props.customField, onChange]);

  if (localeBalanceOperations === null ) { return null; }

  return (
    <List
    header="Balances"
    dataSource={localeBalanceOperations}
    renderItem={(item, idx) => <RenderItem
      value={item}
      balanceOptions={balances}
      operationOptions={balanceOperationType}
      onRemove={removeItem}
      onAdd={addItem}
      onChange={localeOnChange}
      showAdd={idx === localeBalanceOperations.length -1}
      />}
    />
  );
}
