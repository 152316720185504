import { Link } from 'react-router-dom';
import { Button } from '../../../app/components/Forms';
import { Trans } from 'react-i18next';

type ActionProps = {
  onSave(event: React.MouseEvent<HTMLButtonElement>): void;
  disabled: boolean;
}

export const Actions = (props: ActionProps ) => (
  <>
    <Link to="/shops"><Trans i18nKey="cancel" /></Link>
    <Button variant="primary" label="shops.save" onClick={props.onSave} disabled={props.disabled}/>
  </>
);

