import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount, valueSign, entityName, operationValue } from '../utils';
import {selectAvailableBalances} from '../../balances/balancesSlice';
import { selectEntryStatus } from '../entriesSlice';
import { useAppSelector } from '../../../app/hooks';

interface Props {}

export const Balance = (props: Props ) => {
  // Parent component should trigger api fetch
  const entryStatus = useAppSelector(selectEntryStatus);
  const balances = useAppSelector(selectAvailableBalances);
  const { i18n } = useTranslation();
  const balanceValues = useMemo(() => {
    if (entryStatus) {
      // Sum entry values based on balance and balance operation
      const sum = new Map<string, number>();
      entryStatus
      .filter((item:EntrySummary) => item.balanceOperations_id)
      .forEach((item:EntrySummary) => {
        const key = item.balanceOperations_balance;
        sum.set(key, (sum.get(key) || 0) +
          operationValue(item.summary, item.balanceOperations_operation));
      });
      return sum;
    }
    return new Map();
  }, [entryStatus]);

  return (
    <div className="mt-6">
      {balances && balances.map(balance =>
        <div key={balance.id} className="flex justify-between text-base mb-2">
          <span className='mb-2'>
            {entityName(balance, i18n.language as keyof LanguageProperties)}
          </span>
          <div>{`
            ${valueSign(balanceValues.get(balance.id) || 0)}
            ${formatAmount(Math.abs(balanceValues.get(balance.id) || 0))}
            `} &euro;
            </div>
        </div>
      )}
    </div>
  );
};

