import * as React from 'react';
import { Button, LabeledRadioButton } from '../../../app/components/Forms';
import { fieldFragmentOperations } from '../constants';
import {BalanceOperation} from './BalanceOperation';
import { TextField } from './TextField';

interface Props {
  onChange: Function;
  onDelete: Function;
  onBalanceChange: Function;
  customField: FieldFragment;
};

export const CustomFieldFragment: React.FC<Props> = (props: Props) => {
  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    // Use 'data-name' html element attribute because radio button list name would not contain the FieldFragment prop
    // name.
    props.onChange(
      event.currentTarget.getAttribute('data-name') || event.currentTarget.name,
      event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value,
      props.customField.id,
    );
  }

  const onValueChange = (name:string, value:string, language:string) =>
    props.onChange(name, value, props.customField.id, language);

  return (
    <div className='w-full border-dashed border-2 p-4 mt-4'>
      <h3 className='font-bold capitalize float-right'>{props.customField.inputType}</h3>
      {/** NAME */}
      <TextField
        name="name"
        label="custom-fields.custom-field.name"
        values={props.customField.name}
        onChange={onValueChange} />

      {/** DESCRIPTION */}
      <TextField
        name="description"
        label="custom-fields.custom-field.description"
        values={props.customField.description}
        onChange={onValueChange} />

        {props.customField.inputType === 'number' && (
           <div>
           {/** OPERATIONS */}
           {fieldFragmentOperations.map((name) =>
             <LabeledRadioButton
               key={name}
               name={`${props.customField.id}-operation`}
               label={name}
               value={name}
               data-name="operation"
               checked={props.customField.operation === name}
               onChange={onChange} /> )}

           </div>
        )}

      {/** BALANCE OPERATIONS */}
      {props.customField.inputType === 'number' && (
        <BalanceOperation customField={props.customField} onChange={props.onBalanceChange}/>
      )}

      {/** ACTIONS */}
      <div className='mt-4 flex justify-end'>
        <Button label="custom-fields.remove" onClick={() => { props.onDelete(props.customField.id) }} />
      </div>
    </div>
  );
}
