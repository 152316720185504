import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {cloneDeep} from 'lodash';
import { basePath } from './routes';
import { ToolBar } from '../../app/components/ToolBar';
import { Form } from './components/Form';
import { selectFeedback, selectBalance } from './balancesSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { Feedback } from './components/Feedback';
import { findOne, update } from './actions';


export function EditBalance() {
  const dispatch = useAppDispatch();
  const balance = useAppSelector(selectBalance);
  const feedback = useAppSelector(selectFeedback);
  const navigate = useNavigate();
  const { id } = useParams();

  const [localeBalance, setLocaleBalance] = useState<Balance|null>(null);

  // Copy Balance into local Balance for edit
  useEffect(() => {
    setLocaleBalance(cloneDeep(balance));
  }, [balance]);

  // Fetch balance
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(findOne(id));
  }, [dispatch, id]);


  const updateBalance = useCallback((key: keyof Balance , value: string, language: keyof LanguageProperties = 'en') => {
    if (!localeBalance) { return }
    return setLocaleBalance(
      {...localeBalance, [key]: {...localeBalance[key] , [language]: value }}
    );
  }, [localeBalance]);

  const onSubmit = useCallback(() => {
    if (!localeBalance) { return }
    const run = async() => {
      try {
        const result = await dispatch(update({ balance: {...localeBalance }}));
        if ('error' in result) {
          return false;
        }
        navigate(basePath);
      } catch (err) {}
    };
    run();
  }, [dispatch, navigate, localeBalance]);

  return (
    <>
      <ToolBar title='balances.edit' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={feedback.isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        {localeBalance && <Form
          onSubmit={onSubmit}
          balance={localeBalance}
          onChange={updateBalance}
          /> }
        <Feedback />
      </div>
    </>
  )
}
