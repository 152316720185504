import uniq from 'lodash/uniq';

export const userDisplayName = (user: User|UserProfile|undefined|null) =>
  uniq([user?.firstname, user?.lastname]).filter(item => item?.trim().length).join(' ');

export const userAvatarUrl = (user: User|UserProfile|null) => {
  if (!user?.avatar?.path) { return '' };
  return user?.avatar?.path.replace('public/', '/');
}

export const allRoles:UserRole[] = [
  'SUPER_ADMIN',
  'ADMIN',
  'MANAGER',
  'FRANCHISER',
  'USER',
];

export const userRoleRoutes = (userRole:UserRole):string[] => {
  switch(userRole) {
    case 'SUPER_ADMIN':
      return ['register', 'checks', 'users', 'shops', 'custom-fields', 'balances'];
    case 'ADMIN':
    case 'MANAGER':
    case 'FRANCHISER':
      return ['register', 'checks'];
    case 'USER':
      return ['register'];
    default:
      return [];
  }
}
