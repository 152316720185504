import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { Form } from './components/Form';
import { selectFeedback } from './balancesSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { Feedback } from './components/Feedback';
import { create } from './actions';
import { basePath } from './routes';

export function AddBalance() {
  const dispatch = useAppDispatch();
  const feedback = useAppSelector(selectFeedback);
  const navigate = useNavigate();

  const [localeBalance, setLocaleBalance] = useState<Balance>({
    name: { en: '' },
    description: { en: ''},
  });

  const updateBalance = useCallback((key: keyof Balance , value: string, language: keyof LanguageProperties = 'en') => {
    return setLocaleBalance(
      {...localeBalance, [key]: {...localeBalance[key] , [language]: value }}
    );
  }, [localeBalance]);

  const onSubmit = useCallback(() => {
    const run = async() => {
      try {
        const result = await dispatch(create({ balance: {...localeBalance }}));
        if ('error' in result) {
          return false;
        }
        navigate(basePath);

      } catch (err) {}
    };
    run();
  }, [dispatch, navigate, localeBalance]);

  return (
    <>
      <ToolBar title='balances.add' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={feedback.isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <Form onSubmit={onSubmit} balance={localeBalance} onChange={updateBalance} />
        <Feedback />
      </div>
    </>
  )
}
