import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import usersService from './usersService';
import { successToast } from '../toast/toastSlice';
import { handleServiceError, paging  } from '../../api/utils';

const onUploadProgress = () => {};

export const getUsers = createAsyncThunk<any, PageInfo|undefined, { rejectValue: string, dispatch: any }>(
  'users/getUsers',
  async (pageInfo, { rejectWithValue, dispatch }) => {
    try {
      return await usersService().findAll(paging(pageInfo));
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const getUser = createAsyncThunk<any, string, { rejectValue: string, dispatch: any }>(
  'users/getUser',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      return await usersService().getUser({ id });
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const createUser = createAsyncThunk<any, { user: PendingUser, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'users/createUser',
  async ({ user, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result: any = await usersService().create(user);
      if (result && result.id) {
        const newUser = await usersService().getUser({ id: result.id });
        dispatch(successToast('api.user.created'));
        if (cb) {
          cb(newUser);
        }
        return newUser;
      }
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const remove = createAsyncThunk<any, ObjectWithId, { rejectValue: string, dispatch: any }>(
  'users/remove',
  async (user, { rejectWithValue, dispatch }) => {
    try {
      await usersService().remove(user);
      dispatch(successToast('api.user.removed'));
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const update = createAsyncThunk<any, { user: User, shops: Shop[], cb?: Function }, { rejectValue: string, dispatch: any }>(
  'users/update',
  async ({ user, shops, cb }, { rejectWithValue, dispatch }) => {
    try {
      const updateUser = cloneDeep(user);
      // Current api updates user shops in seperate api call used below.
      updateUser.shops = shops;
      const result:any = await usersService().update(updateUser);
      await usersService().attachShops(updateUser, shops);
      if (result && result.id) {
        dispatch(successToast('api.user.updated'));
        if (cb) {
          cb(result);
        }
        return result;
      }
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const attachShops = createAsyncThunk<any, { user: User, shops: Shop[] }, { rejectValue: string, dispatch: any }>(
  'users/attachShop',
  async ({ user, shops }, { rejectWithValue, dispatch }) => {
    try {
      return await usersService().attachShops(user, shops);
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const reset = createAsyncThunk<any, { user: ObjectWithId, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'users/reset',
  async ({ user, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result:any = await usersService().reset(user);
      if (cb) {
        cb(result);
      }
      return result;
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const setImage = createAsyncThunk<any, {user: ObjectWithId, image: File|null, cb?: Function}, { rejectValue: string }>(
  'users/setImage',
  async ({user, image, cb}, { rejectWithValue }) => {
    try {
      if (image) {
        const result = await usersService().upload(user, image, onUploadProgress);
        // if (typeof uploadResult === 'string') {
        //   user.imageUrl = uploadResult;
        // }
        if (cb) {
          cb(result);
        }
        return result;
      }
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const removeImage = createAsyncThunk<any, {user: ObjectWithId, cb?: Function}, { rejectValue: string }>(
  'users/removeImage',
  async ({user, cb}, { rejectWithValue }) => {
    try {
      await usersService().removeImage(user);
      if (cb) {
        cb(user);
      }
      return user;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

