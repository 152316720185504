import { useAppSelector } from '../../../app/hooks';
import { selectFeedback } from '../authenticationSlice';

export function Feedback() {
  const feedback = useAppSelector(selectFeedback);
  if (feedback.error && feedback.message) {
    return (
      <div className="text-white px-6 py-4 border-0 rounded mb-4 bg-red-400">
       {feedback.message.map((item, idx) => (<div key={idx}>{item}</div>))}
      </div>
    )
  }
  return null;
}
