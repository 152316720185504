import * as React from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from '../../../app/components/Forms';
import { TextField } from './TextField';

export interface CustomFieldsFormProps {
  onChange: Function;
  onSubmit: Function;
  customField: FieldDefinition | PendingFieldDefinition | null;
  actions?: React.ReactNode;
  children?: React.ReactNode;
};

export const CustomFieldsForm: React.FC<CustomFieldsFormProps> = (props: CustomFieldsFormProps) => {
  const { t } = useTranslation();
  const { customField } = props;
  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit(null);
  }

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onChange(
      event.currentTarget.name,
      event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value
    );
  }

  if (customField === null) {
    return null;
  }

  return (
    <div className='bg-white p-8'>
      <h1 className='text-3xl font-bold'><Trans i18nKey={'custom-fields.activity-detail'} /></h1>
      <form onSubmit={onSubmit}>

        {/** FIELD NAME */}
        <TextField
          name="name"
          label={t('custom-fields.activity-name')}
          values={customField.name}
          onChange={props.onChange}
          />

         {/** FIELD ACTIVE */}
        <LabeledCheckbox
          name="active"
          label={t('custom-fields.active.label')}
          checked={customField?.active}
          onChange={onChange} />

        {props.actions ? (<div className='ml-auto flex items-center gap-x-4'>{props.actions}</div>) : null }
      </form>
      {props.children}
    </div>
  )
};

export default CustomFieldsForm;
