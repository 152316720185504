import { useAppSelector } from '../../../app/hooks';
import { selectFeedback } from '../customFieldsSlice';

export function Feedback() {
  const feedback = useAppSelector(selectFeedback);
  if (feedback.error && feedback.message) {
    return (
    <div className="text-white px-6 py-4 border-0 rounded mb-4 bg-red-400">
         {feedback.message}
      </div>
    )
  }
  return null;
}
