import { createSlice } from '@reduxjs/toolkit';
import { getResponseError } from '../../api';
import { RootState } from '../../app/store';
import { findOne, findShops, create, createValues, findShopOnDay, findStatus } from './actions';
import {entryDateIsToday} from './utils';

interface entriesState {
  error: boolean | null;
  isWaiting: boolean;
  current: Entry | null;
  message: string;
  // Shops for the current user with only props needed to make entries and active shop FieldDefinitions.
  shops: EntryShop[] | null;
  status: EntrySummary[] | null; // Entry summary is summary of all entry values for each FieldValue
}

const initialState: entriesState = {
  error: false,
  isWaiting: false,
  current: null,
  message: '',
  shops: null,
  status: null,
}

function pending(state:any, message:string = '') {
  state.error = null;
  state.isWaiting = true;
  state.message = message;
}

function fulfilled(state:any, message:string = '') {
  state.error = false;
  state.isWaiting = false;
  state.message = message;
}

function rejected(state:any, message:string = '') {
  state.error = true;
  state.isWaiting = false;
  state.message = message;
}

export const entriesSlice = createSlice({
  name: 'entries',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET SINGLE ENTRY
    .addCase(findOne.pending, (state) => {
      pending(state);
      state.current = null;
    })
    .addCase(findOne.fulfilled, (state, action) => {
      fulfilled(state);
      if (action.payload.item) {
        state.current = action.payload.item;
      } else {
        state.current = action.payload;
      }

    })
    .addCase(findOne.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    // GET SINGLE ENTRY FOR SHOP ON A SPECIFIC DATE
    .addCase(findShopOnDay.pending, (state) => {
      pending(state);
      // state.current = null;
    })
    .addCase(findShopOnDay.fulfilled, (state, action) => {
      fulfilled(state);
      if (!action.payload) {
        const {shop, date} = action.meta.arg;
        if (shop && date) {
          state.current = {
            shop,
            entryDate: date,
            entryValues: [],
          }
          return;
        }
        state.current = null;
      }
      state.current = action.payload;
    })
    .addCase(findShopOnDay.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    // CREATE ENTRY
    .addCase(create.pending, (state) => {
      pending(state, 'Saving entry');
    })
    .addCase(create.fulfilled, (state, action) => {
      fulfilled(state, 'Entry saved')
      state.current = action.payload;
    })
    .addCase(create.rejected, (state, action) => {
      rejected(state, getResponseError(action));
    })
    // CREATE ENTRY VALUES
    .addCase(createValues.pending, (state) => {
      pending(state, 'Saving entry');
    })
    .addCase(createValues.fulfilled, (state, action) => {
      fulfilled(state, 'Entry saved')
      if (state.current !== null) {
        state.current = {
          ...state.current,
          entryValues: action.payload,
        }
      }
    })
    .addCase(createValues.rejected, (state, action) => {
      rejected(state, getResponseError(action));
    })
    // GET SHOPS
    .addCase(findShops.pending, (state) => {
      pending(state);
      state.current = null;
      state.shops = null;
    })
    .addCase(findShops.fulfilled, (state, action) => {
      fulfilled(state);
      state.shops = action.payload;
    })
    .addCase(findShops.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.shops = null;
    })
    // FIND STATUS
    .addCase(findStatus.pending, (state) => {
      pending(state);
    })
    .addCase(findStatus.fulfilled, (state, action) => {
      fulfilled(state);
      if (action.payload.items) {
        state.status = action.payload.items;
      } else {
        state.status = action.payload;
      }
    })
    .addCase(findStatus.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.status = null;
    })
    .addDefaultCase(() => {}),
});

// update LanguageProperties 'current' properties for 'name' and 'description'
export function setShopUserLanguage(shops:EntryShop[]|null, lang:keyof LanguageProperties) {
  if (!shops) { return shops; }
  shops.forEach((shop:EntryShop) => {
    if (shop.fieldDefinitions) {
      shop.fieldDefinitions.forEach((fieldDefinition:FieldDefinition) => {
        fieldDefinition.name.current = fieldDefinition.name[lang] || fieldDefinition.name.en;
        fieldDefinition.fieldFragments.forEach((fieldFragment:FieldFragment) => {
          fieldFragment.name.current = fieldFragment.name[lang] || fieldFragment.name.en;
          fieldFragment.description.current = fieldFragment.description[lang]|| fieldFragment.description.en;
        });
      });
    }
  });
  return shops;
}

// export function setEntryValueLanguage(entryValues:EntryValue[]|null, lang:keyof LanguageProperties) {
//   if (!entryValues) { return entryValues; }
//   entryValues.forEach((entryValue:EntryValue) => {
//       entryValue.fieldFragment.name.current = fieldFragment.fieldFragment.name[lang] || entryValue.fieldFragment.name.en;
//       entryValue.fieldFragment.description.current = entryValue.fieldFragment.description[lang]|| entryValue.fieldFragment.description.en;
//   });
//   return entryValues;
// }

// SELECTORS
export const selectEntry = (state: RootState) => state.entries.current;
export const selectShops = (state: RootState) => state.entries.shops;
export const selectEntryStatus = (state: RootState) => state.entries.status;
export const selectFeedback = (state: RootState): Feedback => ({
  isWaiting: state.entries.isWaiting,
  message: Array.isArray(state.entries.message) ? state.entries.message : [state.entries.message],
  error: state.entries.error,
});
export const selectCanEditEntry = (state: RootState) => {
  if (state.entries.current === null) { return false; }
  if (typeof state.authentication.user?.role === 'undefined') { return false; }
  if (state.authentication.user.role === 'USER' && entryDateIsToday(state.entries.current.entryDate)) { return true; }
  if (state.authentication.user.role === 'FRANCHISER') { return true; }
  if (state.authentication.user.role === 'SUPER_ADMIN') { return true; }
  return false;
}

export const selectCouldEditEntry = (state: RootState) =>
  ['SUPER_ADMIN', 'FRANCHISER', 'USER'].indexOf(state.authentication.user?.role || '') !== -1;

export default entriesSlice.reducer;
