import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { getUser, update, reset, remove } from './actions';
import { cloneDeep } from 'lodash';
import { UserForm } from './components/UserForm';
import { selectUser, selectFeedback } from './usersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinkedShops } from '../../app/components/LinkedShops';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { Feedback } from './components/Feedback';
import { Button } from 'antd';
import { Trans } from 'react-i18next';
import { Popconfirm } from '../../app/components/PopConfirm';

export function EditUser() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const feedback = useAppSelector(selectFeedback);

  const [localeUser, setLocaleUser] = useState<User|null>(null);
  const [localeLinkedShops, setLocaleLinkedShops] = useState<Shop[]>([]);

  // Fetch user
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getUser(id));
  }, [dispatch, id]);

  // Copy User into local User for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    // Only update locale User once after receiving data
    if (user !== null && localeUser === null && id === `${user.id}`) {
      setLocaleUser(cloneDeep(user));
      setLocaleLinkedShops(cloneDeep(user.shops || []));
    }
    if (user && localeUser && user.username !== localeUser.username) {
      setLocaleUser({ ...localeUser, username: user.username });
    }
  }, [user, localeUser, id]);

  const updateUser = useCallback((key: string, value: string) => {
    if (localeUser === null) { return; }
    setLocaleUser({ ...localeUser, [key]: value });
  }, [localeUser]);

  const linkShops = useCallback((shops: Shop[], linked: boolean) => {
    // Add shops to linked shops
    if (linked) {
      setLocaleLinkedShops([...localeLinkedShops, ...shops])
    } else {
      // Remove shops from linked shops
      const newList = localeLinkedShops.reduce((acc, curr) => {
        if (shops.find(shop => shop.id === curr.id)) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as Shop[]);
      setLocaleLinkedShops(newList);
    }
  }, [localeLinkedShops]);

  const onSubmit = useCallback(() => {
    if (localeUser === null) { return; }
    dispatch(update({ user: {...localeUser}, shops: [...localeLinkedShops]   }));
  }, [dispatch, localeUser, localeLinkedShops]);

  const removeUser = useCallback(async () => {
    if (typeof id !== 'string') { return; }
    await dispatch(remove({ id }));
    navigate('/users');
  }, [dispatch, id, navigate]);

  const resetUser = useCallback(() => {
    if (typeof id !== 'string') { return; }
    dispatch(reset({user: { id }}));
  }, [dispatch, id]);

  return (
    <>
      <ToolBar title='users.edit' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={feedback.isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <UserForm onSubmit={onSubmit} user={localeUser} onChange={updateUser}
        actions={(
          <>
            {/** REMOVE USER */}
            <Popconfirm title="users.delete" onConfirm={removeUser}>
              <Button type="primary"><Trans i18nKey="users.delete" /></Button>
            </Popconfirm>
            {/** RESET USER */}
            <Popconfirm onConfirm={resetUser}>
              <Button> <Trans i18nKey="users.reset" /></Button>
            </Popconfirm>
          </>
        )}
        />
        {localeLinkedShops && <LinkedShops onChange={linkShops} assignedShops={localeLinkedShops} /> }
      </div>
      <Feedback />
    </>
  )
}
