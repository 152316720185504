import {useState, useEffect, useCallback } from 'react';
import difference from 'lodash/difference';
import { findAll } from '../../features/shops/actions';
import { selectShops } from '../../features/shops/shopsSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SimpleTable } from './SimpleTable';

interface LinkedShopsProps {
  assignedShops: Shop[] | null;
  onChange: Function;
  children?: React.ReactNode;
}

export const LinkedShops: React.FC<LinkedShopsProps> = (props: LinkedShopsProps) => {
  const dispatch = useAppDispatch();
  const shops = useAppSelector(selectShops);
  const [availableShops, setAvailableShops] = useState<Shop[]>([]);
  const { onChange } = props;

  useEffect(() => {
    dispatch(findAll());
  }, [dispatch]);

  useEffect(() => {
    if (!shops) { return; }
    if (props.assignedShops) {
      const availableShopIds = difference(shops.map(c => c.id), props.assignedShops.map(c => c.id));
      setAvailableShops(shops.filter(c => availableShopIds.includes(c.id)) );
    } else {
      setAvailableShops(shops);
    }
  }, [shops, props.assignedShops]);

  const onUpdate = useCallback((selectedIds:string[], assign:boolean) => {
    if (!shops) { return; }
    onChange(shops.filter(shop => selectedIds.indexOf(shop.id) !== -1), assign);
  }, [shops, onChange])

  return (
    <div className='o-box bg-white p-8 w-full'>
      {/** ASSIGNED SHOPS */}
      <div className='mb-4'>
        <SimpleTable
        title="Assigned shops"
        actionName="Unassign shops"
        header="Shop"
        dataSource={props.assignedShops || []}
        onChange={(selectedRowKeys:string[]) => {onUpdate(selectedRowKeys, false)}}
        />
      </div>
      {/** AVAILABLE SHOPS */}
      <div className='mb-4'>
        <SimpleTable
        title="Other shops"
        actionName="Assign shops"
        header="Shop"
        dataSource={availableShops}
        onChange={(selectedRowKeys:string[]) => {onUpdate(selectedRowKeys, true)}}
        />
      </div>
    </div>
  );
}

export default LinkedShops;
