import * as React from 'react';
import uniqueId from 'lodash/uniqueId';
import { Trans } from 'react-i18next';

interface TextField extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  defaultValue?: any;
};

export const LabeledTextField: React.FC<TextField> = (props: TextField) => {
  const { label, defaultValue, id, ...rest } = props; // Make sure only HTMLInputElement props remain in 'rest'.
  const localId = id || uniqueId('labeled-text-field');
  return (
    <div className='mb-4'>
      <label htmlFor={localId}>
        <div className='pb-2'><Trans i18nKey={label} />
        {props.required === false && ( <span className="ml-2 text-gray-400">(<Trans i18nKey="optional" />)</span>)}</div>
        <input className='border p-2 rounded w-full' id={localId} value={defaultValue} {...rest} />
      </label>
    </div>
  )
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  variant?: 'none' | 'primary' | 'secondary';
  children?: React.ReactNode;
};

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const classes = ['border rounded p-2 px-4 select-none bg-white'];
  const { variant, label, type, children, ...rest } = props; // Make sure only  HTMLButtonElement props remain in 'rest'.
  if (variant === 'primary') {
    classes.push('!bg-primary-dark text-white');
  }
  return (
    <button
    className={classes.join(' ')}
    type={type || 'button'}
    {...rest}
    >
    {label && <Trans i18nKey={label} />}
    {children}
    </button>
  )
};

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | React.ReactNode;
  children?: React.ReactNode;
};

export const LabeledCheckbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { label, id, children, ...rest } = props; // Make sure only HTMLInputElement props remain in 'rest'.
  const localId = id || uniqueId('labeled-checkbox');
  return (
    <div className='mb-4'>
      <label htmlFor={localId}>
        <input type="checkbox" id={localId} {...rest} />
        <span className='px-2'>
          {typeof label === 'string' && <Trans i18nKey={label} />}
          {typeof label !== 'string' && label}
        </span>
        {children}
      </label>
    </div>
  )
};

interface RadiobuttonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
};

export const LabeledRadioButton: React.FC<RadiobuttonProps> = (props: RadiobuttonProps) => {
  const { label, id, ...rest } = props; // Make sure only HTMLInputElement props remain in 'rest'.
  const localId = id || uniqueId('labeled-checkbox');
  return (
    <div className='mb-4'>
      <label htmlFor={localId}>
        <input type="radio" id={localId} {...rest} />
        <span className='px-2'><Trans i18nKey={label} /></span>
      </label>
    </div>
  )
};


interface DatePickerProps extends React.InputHTMLAttributes<HTMLInputElement> { };

export const DatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const { id, pattern, ...rest } = props; // Make sure only HTMLInputElement props remain in 'rest'.
  return (
    <input
      type="date"
      className='border rounded px-4 text-sm'
      id={id || uniqueId('datepicker')}
      pattern={pattern || "d{4}-d{2}-d{2}"}
      {...rest} />
  );
};
