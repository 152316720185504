export const fieldFragmentOperations = [
  'add-to-total' ,
  'subtract-from-total',
  'ignore-total',
];

export const balanceOperationType = [
  'add-to-balance',
  'subtract-from-balance',
  'ignore-balance',
];

