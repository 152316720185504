import { useAppSelector } from '../../../app/hooks';
import { selectFeedback } from '../balancesSlice';

export function Feedback() {
  const feedback = useAppSelector(selectFeedback);
  if (feedback.error && feedback.message && feedback.message.join('').length > 0) {
    console.log(feedback.error, feedback.message.join(''));
    return (
    <div className="text-white px-6 py-4 border-0 rounded mb-4 bg-red-400">
         {feedback.message}
      </div>
    )
  }
  return null;
}
