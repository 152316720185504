import * as React from 'react';
import { Trans } from 'react-i18next';
import { TextField } from '../../custom-fields/components/TextField';

export interface FormProps {
  onChange: Function;
  onSubmit: Function;
  balance: Balance;
  actions?: React.ReactNode;
  children?: React.ReactNode;
};

export const Form: React.FC<FormProps> = (props: FormProps) => {
  const { balance } = props;
  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit(null);
  }

  if (!balance) {
    return null;
  }

  return (
    <div className='bg-white p-8'>
      <h1 className='text-3xl font-bold'><Trans i18nKey={'balances.detail'} /></h1>
      <form onSubmit={onSubmit}>
        {/** NAME */}
        <TextField
          name="name"
          label={<Trans i18nKey='balances.detail.name' />}
          values={balance.name}
          onChange={props.onChange}
          />
        {/** DESCRIPTION */}
        <TextField
          name="description"
          label="balances.detail.description"
          values={balance.description}
          onChange={props.onChange} />
        {/** ACTIONS */}
        {props.actions ? (<div className='ml-auto flex items-center gap-x-4'>{props.actions}</div>) : null }
      </form>
      {props.children}
    </div>
  )
};

export default Form;
