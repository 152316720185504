import { format, add, isBefore, startOfToday } from 'date-fns';
import get from 'lodash/get';

export const entryDateFormat = 'yyyy-MM-dd';
export const historyDateFormat = 'yyyy-MM-dd HH:mm';

const digitCount = 2;
const localNumberConfig = {
  // style: 'currency',
  // currency: 'EUR',
  minimumFractionDigits: digitCount,
  maximumFractionDigits: digitCount,
}

export const asEntryDate = (date: Date) => date ? format(new Date(date), entryDateFormat) : '';
export const asHistoryDate = (date: Date) => date ? format(new Date(date), historyDateFormat) : '';
export const today = startOfToday;
export const entryDateIsToday = (entryDate: string) => entryDate === asEntryDate(new Date());
export const previousDay = (date: Date|string) => add(new Date(date), { days: -1 });
export const nextDay = (date: Date, max?: Date) => {
  if (max && !isBefore(new Date(date), new Date(max))) {
    return date;
  }
  return add(new Date(date), { days: 1 });
}

export const operationValue = (value:number|undefined, operation:FieldFragmentOperation|string|undefined) => {
  if (typeof value !== 'number') {
    return 0;
  }
  if (typeof operation === 'undefined') {
    return value;
  }
  if (operation.startsWith('add')) {
    return value * 1;
  }
  if (operation.startsWith('subtract')) {
    return value * -1;
  }
  return value * -1;
}

export const entrySummaryOperationValue = (entry:EntrySummary) => {
  if (typeof entry.summary !== 'number') {
    return 0;
  }
  if (entry.fieldFragment_operation === 'add-to-total') {
    return entry.summary * 1;
  }
  if (entry.fieldFragment_operation === 'subtract-from-total') {
    return entry.summary * -1;
  }
  if (entry.fieldFragment_operation === 'ignore-total') {
    return 0;
  }
  return entry.summary * -1;
};

export const fieldFragmentName = (fieldFragment: FieldFragment | ObjectWithId, lang?: keyof LanguageProperties) =>
  get(fieldFragment, `name.${lang || 'en'}`);


export const entityName = (entity: ObjectWithName, lang?: keyof LanguageProperties) => {
  if (lang === 'en') {
    return get(entity, 'name.en');
  }
  return get(entity, `name.${lang}`, get(entity, 'name.en'));
}

export const formatAmount = (amount:number|string|undefined) => {
  if (typeof amount === 'string') { return parseFloat(amount).toLocaleString(undefined, localNumberConfig); }
  if (typeof amount === 'number') { return amount.toLocaleString(undefined, localNumberConfig); }
  return '';
}

export const currency = (value:string|undefined) => {
  if (typeof value === 'string' && value.length !== 0) { return `${value} €` }
  return '';
}

export const valueSign = (value?:number) =>  {
  if (typeof value === 'number') {
    if (value >= 0) {
      return '+';
    } else {
      return '-';
    }
  }
  return '';
}

export const operatorSign = (operation:FieldFragmentOperation, value?:number) =>  {
  if (operation === 'subtract-from-total') {
    return '-';
  }
  if (operation === 'ignore-total') {
    return ' ';
  }
  if (operation === 'daily-total') {
    return valueSign(value);
  }
  return '+';
}

export const getName = (obj:any) => {
  if (typeof obj === 'undefined') {
    return '';
  }
  if (typeof obj === 'string') {
    return obj;
  }
  if (typeof obj.name === 'string') {
    return obj.name;
  }
  if (obj) {
    return obj?.name?.en;
  }
  return undefined;
}
