import { useMemo } from 'react';
import { Button, DatePicker } from '../../../app/components/Forms';
import { useAppSelector } from '../../../app/hooks';
import { asEntryDate, previousDay, nextDay } from '../utils';
import { Loading } from '../../../app/components/Loading';
import { selectCanEditEntry, selectCouldEditEntry } from '../entriesSlice';

type ActionProps = {
  onSave(event: React.MouseEvent<HTMLButtonElement>): void;
  onViewChange: Function;
  view: ViewMode;
  disabled: boolean;
  onChangeDate: Function;
  entryDate: string;
}

export const Actions = (props: ActionProps ) => {
  const canEdit = useAppSelector(selectCanEditEntry);
  const couldEdit = useAppSelector(selectCouldEditEntry);
  const today = useMemo(() => asEntryDate(new Date()), []);

  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeDate(event.target.value);
  };

  const onPreviousDay = () => {
    props.onChangeDate(previousDay(new Date(props.entryDate)));
  }

  const onNextDay = () => {
    const newDate = nextDay(new Date(props.entryDate), new Date(today));
    if (newDate && asEntryDate(newDate) !== props.entryDate) {
      props.onChangeDate(newDate);
    }
  }

  const disabled = useMemo(() => {
    if (!couldEdit || !canEdit || props.disabled) { return true; }
    return false;
  }, [couldEdit, canEdit, props.disabled]);

  return (
    <>
      <div className="flex content-center space-x-2">
        <Button label="nav.previousEntry" onClick={onPreviousDay} />
        <DatePicker onChange={onChangeDate} max={today} value={props.entryDate} />
        <Button label="nav.nextEntry" onClick={onNextDay} />
      </div>
      {(couldEdit && props.view === 'read') && <Button variant="primary" label="entries.edit" onClick={() => { props.onViewChange('edit') }} disabled={disabled}/> }
      {(couldEdit && props.view !== 'read') && <Button variant="primary" label="entries.save" onClick={props.onSave} disabled={disabled}/> }
      {(props.disabled )&& <div className="absolute" style={{right:-30}}> <Loading/> </div>}
    </>
  );
}

