import { useMemo } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectEntryStatus } from '../entriesSlice';
import { entrySummaryOperationValue } from '../utils';
import {Trans} from 'react-i18next';
import { formatAmount } from '../utils';

interface Item {
  id: string;
  name: string;
  summary: number|string;
}

interface ListProps {
  items: Item[] | null;
}

const calc = (fieldDefinitions: FieldDefinition[], status:EntrySummary[]) => {
  return fieldDefinitions
  .map((item:FieldDefinition) => {
    const summaryValues = ((status && status.filter((status:EntrySummary) =>
      status.fieldFragment_fieldDefinitionId === item.id
    )
    .map((status:EntrySummary) => entrySummaryOperationValue(status))
    ) || []);
    return {
      id: item.id,
      name: item.name.en,
      summary: summaryValues.length === 0 ? '0' : summaryValues.reduce((acc, curr) => acc + curr, 0),
  }});
}

const List = (props: ListProps ) => {
  if (!props.items) { return null; }
  return (
    <div>
      {props.items && props.items.map(item => (
        <div key={item.id} className="flex justify-between text-base">
          <div>{item.name} <Trans i18nKey="custom-fields.summary-end" /></div>
          <div>{formatAmount(item.summary)} &euro;</div>
        </div>
      ))}
    </div>
  );
}

interface StatusProps {
  entry: Entry;
  shop: EntryShop | undefined;
  entryDate: string;
}

export const Status = (props: StatusProps ) => {
  // Parent component should trigger api fetch
  const entryStatus = useAppSelector(selectEntryStatus);

  // Summary of each FieldDefinition
  const items = useMemo(() => {
    if (!props.shop || ! entryStatus) { return []; }
    return calc(props.shop.fieldDefinitions, entryStatus);
  }, [props.shop, entryStatus]);

  // Total sum of all item.summary items
  const cashStart = useMemo(() => {
    return items.reduce<string|number>((acc, curr) => {
      if (typeof curr.summary === 'number') {
        if (typeof acc !== 'number') { acc = 0; }
        acc = acc + curr.summary
      }
      return acc;
    }, '0');
  }, [ items ]);

  if (!props.shop) {
    return null;
  }

  return (
    <div className='divide-y divide-gray-light  space-y-4'>
      <List items={items} />
      <div className="flex justify-between text-base pt-4">
        <div><Trans i18nKey="cash-start" /></div>
        <div className="font-bold">{formatAmount(cashStart)} &euro;</div>
      </div>
    </div>
  );
}
