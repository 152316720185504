import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ToolBar } from '../../app/components/ToolBar';
import { Button } from '../../app/components/Forms';
import { LinkedShops } from '../../app/components/LinkedShops';
import { create } from './actions';
import { CustomFieldsForm } from './components/CustomFieldsForm';
import { CustomFieldFragment } from './components/CustomFieldFragment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectFeedback } from './customFieldsSlice';
import { Actions } from './components/ToolBarActions';
import { Nav } from './components/ToolBarNav';
import { Feedback } from './components/Feedback';
import { defaultCustomField, defaultFieldDefinition } from './util';

export function AddCustomFields() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const feedback = useAppSelector(selectFeedback);

  const [localeFieldFragments, setLocaleFieldFragments] = useState<Array<FieldFragment>>([
    // defaultCustomField(),
  ]);
  const [localeCustomFieldDefinition, setLocaleCustomFieldDefinition] = useState<PendingFieldDefinition>(
    defaultFieldDefinition()
  );
  const [localeLinkedShops, setLocaleLinkedShops] = useState<Shop[]>([]);

  const linkShops = useCallback((shops: Shop[], linked: boolean) => {
    // Add shops to linked shops
    if (linked) {
      setLocaleLinkedShops([...localeLinkedShops, ...shops])
    } else {
      // Remove shops from linked shops
      const newList = localeLinkedShops.reduce((acc, curr) => {
        if (shops.find(shop => shop.id === curr.id)) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as Shop[]);
      setLocaleLinkedShops(newList);
    }
  }, [localeLinkedShops]);

  const updateDefinition = useCallback((name: string, value: string) => {
    if (name === 'name') {
      return setLocaleCustomFieldDefinition(
        {...localeCustomFieldDefinition, [name]: {...localeCustomFieldDefinition[name] , en: value }}
      );
    }
    setLocaleCustomFieldDefinition({ ...localeCustomFieldDefinition, [name]: value });
  } , [localeCustomFieldDefinition]);

  const updateFragment = useCallback((name: string, value: string, id: string) => {
    const newCustomFields = localeFieldFragments.map(customField => {
      if (customField.id === id) {
        if (name === 'name'  || name === 'description') {
          return { ...customField, [name]: { ...customField[name], en: value }};
        }
        return { ...customField, [name]: value };
      }
      return customField;
    });
    setLocaleFieldFragments(newCustomFields);
  } , [localeFieldFragments]);

  const addFragment = useCallback((inputType:InputType) => {
    setLocaleFieldFragments([...localeFieldFragments, defaultCustomField(inputType) ]);
  }, [localeFieldFragments]);

  const removeFragment = useCallback((id: string) => {
    setLocaleFieldFragments(localeFieldFragments.filter(curr => curr.id !== id));
  }, [localeFieldFragments]);

  const updateFragmentBalanceOperations = useCallback((fieldFragmentId: string, balanceOperations: BalanceOperation[]) => {
    setLocaleFieldFragments(localeFieldFragments.map(item => {
      if (item.id === fieldFragmentId) {
        item.balanceOperations = balanceOperations;
      }
      return item;
    }));
  }, [localeFieldFragments]);

  const onSubmit = useCallback(() => {
    const cb = () => {
      navigate('/custom-fields');
    };
    const newCustomFields = {
      ...localeCustomFieldDefinition,
      fieldFragments: [...localeFieldFragments],
      shops: [...localeLinkedShops],
    };
    dispatch(create({
      customFields: newCustomFields,
    cb }));
  }, [dispatch, navigate, localeCustomFieldDefinition, localeFieldFragments, localeLinkedShops]);

  return (
    <>
      <ToolBar title='custom-fields.activity.add' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={feedback.isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <div className='o-box w-full'>

          {/** CUSTOM FIELDS, DEFINITION */}
          <CustomFieldsForm onSubmit={onSubmit} customField={localeCustomFieldDefinition} onChange={updateDefinition} >
            <Feedback />
          </CustomFieldsForm>

          {/** CUSTOM FIELDS, FRAGMENTS */}
          <div className='o-box bg-white p-8 mt-8'>
            <h1 className='text-3xl font-bold'><Trans i18nKey={'custom-fields.custom-fields'} /></h1>
            {localeFieldFragments && localeFieldFragments.map(fragment => <CustomFieldFragment
            key={fragment.id}
            customField={fragment}
            onChange={updateFragment}
            onDelete={removeFragment}
            onBalanceChange={updateFragmentBalanceOperations}
            />)}
            <div className='mt-4'>
               <Button label="custom-fields.add-number-field" onClick={() => addFragment('number') } />
               <Button label="custom-fields.add-text-field" onClick={() => addFragment('text')} />
            </div>
          </div>

        </div>
        <LinkedShops onChange={linkShops} assignedShops={feedback.isWaiting ? null : localeLinkedShops} />
      </div>
    </>
  )
}
