import {uniqueId} from 'lodash';

export function defaultCustomField(inputType:InputType): FieldFragment {
  return {
    id: uniqueId(),
    name: { en: '', nl: '', fr: '' },
    description: { en: '', nl: '', fr: '' },
    operation: 'ignore-total',
    inputType,
    balanceOperations: [],
  };
}

export function defaultFieldDefinition(): PendingFieldDefinition {
  return {
    id: uniqueId(),
    name: { en: '', nl: '', fr: '' },
    active: false,
    fieldFragments: [],
    type: 'ACTIVITY',
  };
}
