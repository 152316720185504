import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { findOne, update, remove } from './actions';
import { cloneDeep } from 'lodash';
import { Form } from './components/Form';
import { Button } from 'antd';
import { selectShop, selectFeedback } from './shopsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinkedUsers } from '../../app/components/LinkedUsers';
import { LinkedFields } from '../../app/components/LinkedFields';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { Trans } from 'react-i18next';
import { Popconfirm } from '../../app/components/PopConfirm';

export function EditShop() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const shop = useAppSelector(selectShop);
  const feedback = useAppSelector(selectFeedback);
  const navigate = useNavigate();

  const [localeShop, setLocaleShop] = useState<Shop|null>(null);
  const [localeLinkedUsers, setLocaleLinkedUsers] = useState<User[]>([]);
  const [localeLinkedFields, setLocaleLinkedFields] = useState<FieldDefinition[]>([]);

  // Fetch shop
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(findOne(id));
  }, [dispatch, id]);

  // Copy Shop into local Shop for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    // Only update locale Shop once after receiving data
    if (shop !== null && localeShop === null && id === `${shop.id}`) {
      setLocaleShop(cloneDeep(shop));
      setLocaleLinkedUsers(cloneDeep(shop.users || []));
      setLocaleLinkedFields(cloneDeep(shop.fieldDefinitions || []));
    }
  }, [shop, localeShop, id]);

  const updateShop = useCallback((key: string, value: string) => {
    if (localeShop === null) { return; }
    setLocaleShop({ ...localeShop, [key]: value });
  }, [localeShop]);

  const linkUsers = useCallback((users: User[], linked: boolean) => {
    // Add user to linked users
    if (linked) {
      setLocaleLinkedUsers([...localeLinkedUsers, ...users])
    } else {
      // Remove user from linked users
      const newList = localeLinkedUsers.reduce((acc, curr) => {
        if (users.find(user => user.id === curr.id)) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as User[]);
      setLocaleLinkedUsers(newList);
    }
  }, [localeLinkedUsers]);

  const linkField = useCallback((field: FieldDefinition, linked: boolean) => {
    // Add field to linked fields
    if (linked) {
      if (localeLinkedFields.find(curr => curr.id === field.id)) {
        return;
      }
      setLocaleLinkedFields([...localeLinkedFields, field])
    } else {
      // Remove field from linked fields
      setLocaleLinkedFields(localeLinkedFields.filter(curr => curr.id !== field.id));
    }
  }, [localeLinkedFields]);

  const onSubmit = useCallback(() => {
    if (localeShop === null) { return; }
    dispatch(update({ shop: {...localeShop}, users: [...localeLinkedUsers], fields: [...localeLinkedFields] }));
  }, [dispatch, localeShop, localeLinkedUsers, localeLinkedFields]);

  const removeShop = useCallback(async () => {
    if (typeof id !== 'string') { return; }
    console.log(id);
    await dispatch(remove({ id }));
    navigate('/shops');
  }, [dispatch, id, navigate]);

  return (
    <>
      <ToolBar title='shops.edit' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={feedback.isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <Form onSubmit={onSubmit} shop={localeShop} onChange={updateShop}
        actions={
          <Popconfirm title="shops.delete" onConfirm={removeShop}>
            <Button type="primary"><Trans i18nKey="shops.delete" /></Button>
          </Popconfirm>
        }
        >
          <LinkedFields onChange={linkField} assigendFields={feedback.isWaiting ? null : localeLinkedFields} />
        </Form>
        <LinkedUsers onChange={linkUsers} assigendUsers={feedback.isWaiting ? null : localeLinkedUsers} />
      </div>
    </>
  )
}
