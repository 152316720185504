import {useState, useEffect} from 'react';
import difference from 'lodash/difference';
import { Trans } from 'react-i18next';
import { findAll } from '../../features/custom-fields/actions';
import { selectCustomFields } from '../../features/custom-fields/customFieldsSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { LabeledCheckbox } from './Forms';

interface ItemProps {
  id: string;
  name: string; // FieldDefinition name (en)
  active: boolean;  // FieldDefinition active
  checked: boolean;
  onChange: React.FormEventHandler;
  disabled: boolean;
}

const Item: React.FC<ItemProps> = (props: ItemProps) => (
    <LabeledCheckbox
      value={props.id} checked={props.checked} onChange={props.onChange} disabled={props.disabled}
      label={props.name}>

      {props.active === false && (
        <span className='text-gray-400 font-semi-bold'><Trans i18nKey={'inactive'} /></span>
      )}
    </LabeledCheckbox>
);


interface ListProps {
  title: React.ReactNode;
  onChange: Function;
  fields: FieldDefinition[] | null;
  checked: boolean;
  disabled: boolean;
  empty?: React.ReactNode;
}

const List = (props: ListProps) => (
  <div className='mb-4'>
    <h2 className='text-2xl font-bold mb-2'>{props.title}</h2>
    <div className='divide-y grid grid-cols-1'>
      {props.fields?.map((c:FieldDefinition) => <Item key={c.id} name={c.name.en} checked={props.checked}
        id={c.id}
        active={c.active}
        onChange={() => { props.onChange(c, !props.checked) }}
        disabled={props.disabled} />)}
    </div>
    {props.fields && props.fields.length === 0 && props.empty}
  </div>
)

interface LinkedFieldsProps {
  assigendFields: FieldDefinition[] | null;
  onChange: Function;
  children?: React.ReactNode;
}

export const LinkedFields: React.FC<LinkedFieldsProps> = (props: LinkedFieldsProps) => {
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectCustomFields);
  const [availableFields, setAvailableFields] = useState<FieldDefinition[]>([]);

  useEffect(() => {
    dispatch(findAll());
  }, [dispatch]);

  // Filter available fields
  useEffect(() => {
    if (!fields) { return; }
    if (props.assigendFields) {
      const availableFieldIds = difference(fields.map(c => c.id), props.assigendFields.map(c => c.id));
      setAvailableFields(fields.filter(c => availableFieldIds.includes(c.id)) );
    } else {
      setAvailableFields(fields);
    }
  }, [fields, props.assigendFields]);

  // @TODO Each row has an onchange event, add one event for all rows?
  return (
    <div className='o-box bg-white p-8 w-full'>
      {/** ASSIGNED FIELDS */}
      <List
        fields={props.assigendFields}
        title={<><Trans i18nKey="Assigned activities" /><span className='text-base font-normal pl-2'>({props.assigendFields?.length || 0})</span></>}
        checked={true}
        onChange={props.onChange}
        disabled={!Array.isArray(props.assigendFields)}
        empty={<div><Trans i18nKey="no assigned fields" /></div>}
      />

      {/** AVAILABLE FIELDS */}
      <List
        fields={availableFields}
        title={<Trans i18nKey="Other activities" />}
        checked={false}
        onChange={props.onChange}
        disabled={!Array.isArray(props.assigendFields)}
        empty={<div><Trans i18nKey="no fields available" /></div>}
      />

      {props.children}
    </div>
  )

}

