import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkButton } from '../../app/components/LinkButton';
import { ToolBar } from '../../app/components/ToolBar';
import { Trans } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { findAll } from './actions';
import { selectBalances } from './balancesSlice';
import { ToolBarSearch } from '../../app/components/ToolBarSearch';

interface ActionsProps {
  onSearch: Function;
};
const Actions = (props: ActionsProps) => (
  <>
    <ToolBarSearch placeholder="balances.search" onChange={props.onSearch} />
    <LinkButton to="/balances/add" label="balances.add" />
  </>
);

export function Overview() {
  const balances = useAppSelector(selectBalances);
  const dispatch = useAppDispatch();
  const [localeBalances, setLocaleBalances] = useState<Balance[]>([]);

  // Filter locale balances
  const filterBalances = useCallback((value:string) => {
    if (!balances) { return; }
    if (value && value.trim().length < 2) {
      setLocaleBalances(balances);
    }
    const filterValue = value.trim();
    setLocaleBalances(balances.filter((item) => item.name.en.toLowerCase().includes(filterValue)));
  }, [balances]);

  // Fetch balances to store
  useEffect(() => {
    dispatch(findAll());
  }, [dispatch]);

  // Copy locale balances from store
  useEffect(() => {
    if (!balances) { return; }
    setLocaleBalances(balances);
  }, [balances]);

  return (<>
    {/** */}
    <ToolBar title='balances.title' actions={<Actions onSearch={filterBalances}/>} />
    {/** */}
    <div className='o-table'>
      <table>
        <thead>
          <tr>
            <td><Trans i18nKey="name" /></td>
          </tr>
        </thead>
        <tbody>
        {localeBalances.map(item => (
          <tr className='o-table-row' key={item.id} >
            <td><Link to={`/balances/edit/${item.id}`} >{item.name.en || '-'}</Link></td>
            <td></td>
          </tr> ))}
        </tbody>
      </table>
    </div>
  </>
  )
}
