import { useCallback, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { ToolBar } from '../../app/components/ToolBar';
import { LoadingIndicator } from '../../app/components/LoadingIndicator';
import { Button } from '../../app/components/Forms';
import { LinkedShops } from '../../app/components/LinkedShops';
import { findOne, update } from './actions';
import { findAll as findAllBalances } from '../balances/actions';
import { CustomFieldFragment } from './components/CustomFieldFragment';
import { CustomFieldsForm } from './components/CustomFieldsForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectCustomField, selectFeedback } from './customFieldsSlice';
import { Actions } from './components/ToolBarActions';
import { Nav } from './components/ToolBarNav';
import { Feedback } from './components/Feedback';
import { defaultCustomField } from './util';

export function EditCustomFields() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const feedback = useAppSelector(selectFeedback);
  const customFieldDefinition = useAppSelector(selectCustomField);

  const [localeFieldFragments, setLocaleFieldFragments] = useState<Array<FieldFragment>>([]);
  const [localeFieldDefinition, setLocaleFieldDefinition] = useState<FieldDefinition|null>(null);
  const [localeLinkedShops, setLocaleLinkedShops] = useState<Shop[]>([]);

  // Fetch customField
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(findOne(id));
    // Get the list of balances the select in the fieldFragments
    dispatch(findAllBalances());
  }, [dispatch, id]);


  // Copy FieldDefinition into local FieldDefinition for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    if (
      (customFieldDefinition !== null && localeFieldDefinition === null && id === `${customFieldDefinition.id}`)
      || (customFieldDefinition !== null && localeFieldDefinition !== null && id === `${customFieldDefinition.id}` && customFieldDefinition?.updatedAt !== localeFieldDefinition.updatedAt)
    ) {
      setLocaleFieldDefinition({...customFieldDefinition});
      setLocaleFieldFragments(cloneDeep(customFieldDefinition.fieldFragments || []));
      setLocaleLinkedShops(cloneDeep(customFieldDefinition.shops || []));
    }
  }, [localeFieldDefinition, customFieldDefinition, id]);

  const linkShops = useCallback((shops: Shop[], linked: boolean) => {
    // Add shops to linked shops
    if (linked) {
      setLocaleLinkedShops([...localeLinkedShops, ...shops])
    } else {
      // Remove shops from linked shops
      const newList = localeLinkedShops.reduce((acc, curr) => {
        if (shops.find(shop => shop.id === curr.id)) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as Shop[]);
      setLocaleLinkedShops(newList);
    }
  }, [localeLinkedShops]);

  const updateDefinition = useCallback((name: string, value: string, language: string = 'en') => {
    if (localeFieldDefinition === null) { return; }
    if (name === 'name') {
      return setLocaleFieldDefinition(
        {...localeFieldDefinition, [name]: {...localeFieldDefinition[name] , [language]: value }}
      );
    }
    setLocaleFieldDefinition({ ...localeFieldDefinition, [name]: value });
  } , [localeFieldDefinition]);

  const updateFragment = useCallback((name: string, value: string, id: string, language: string = 'en') => {
    const newCustomFields = localeFieldFragments.map(customField => {
      if (customField.id === id) {
        if (name === 'name'  || name === 'description') {
          return { ...customField, [name]: { ...customField[name], [language]: value }};
        }
        return { ...customField, [name]: value };
      }
      return customField;
    });
    setLocaleFieldFragments(newCustomFields);
  } , [localeFieldFragments]);

  const updateFragmentBalanceOperations = useCallback((fieldFragmentId: string, balanceOperations: BalanceOperation[]) => {
    setLocaleFieldFragments(localeFieldFragments.map(item => {
      if (item.id === fieldFragmentId) {
        item.balanceOperations = balanceOperations;
      }
      return item;
    }));
  }, [localeFieldFragments]);

  const addFragment = useCallback((inputType:InputType) => {
    setLocaleFieldFragments([...localeFieldFragments, defaultCustomField(inputType) ]);
  }, [localeFieldFragments]);

  const removeFragment = useCallback((id: string) => {
    setLocaleFieldFragments(localeFieldFragments.filter(curr => curr.id !== id));
  }, [localeFieldFragments]);

  const onSubmit = useCallback(async() => {
    if (localeFieldDefinition === null) { return; }

    const fieldFragments = localeFieldFragments.reduce((acc, curr) => {
      const newfieldfragment = cloneDeep(curr);
      newfieldfragment.balanceOperations = newfieldfragment.balanceOperations
      // Ignore balanceOperation if it has not both operation and balance
      .filter(item => item.operation && item.balance?.id)
      .map(item => {
        if (item?.id.indexOf('NEW') === 0) {
          const newItem = {...item};
          delete newItem.id;
          return newItem;
        }
        return item;
      });
      acc.push(newfieldfragment);
      return acc;
    }, [] as FieldFragment[]);

    await dispatch(update({
      customFields: {
        ...localeFieldDefinition,
        fieldFragments,
      },
      shops: [...localeLinkedShops],
    }));
    navigate('/custom-fields');
   }, [dispatch, localeFieldDefinition, localeFieldFragments, localeLinkedShops, navigate]);

  return (
    <>
      <ToolBar title='custom-fields.activity.edit' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={feedback.isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <div className='o-box w-full'>
          {feedback.isWaiting && <LoadingIndicator />}
          {/** FIELD-DEFINITION */}
          <CustomFieldsForm
            onSubmit={onSubmit}
            customField={localeFieldDefinition}
            onChange={updateDefinition}
          >
            <Feedback />
          </CustomFieldsForm>

          {/** FIELD-FRAGMENTS */}
          <div className='o-box bg-white p-8 mt-8'>
            <h1 className='text-3xl font-bold'><Trans i18nKey={'custom-fields.custom-fields'} /></h1>
            {localeFieldFragments && localeFieldFragments.map(fragment => <CustomFieldFragment
             key={fragment.id}
             customField={fragment}
             onChange={updateFragment}
             onDelete={removeFragment}
             onBalanceChange={updateFragmentBalanceOperations}
             />)}
             <div className='mt-4'>
                <Button label="custom-fields.add-number-field" onClick={() => addFragment('number') } />
                <Button label="custom-fields.add-text-field" onClick={() => addFragment('text')} />
             </div>
          </div>

        </div>
        <LinkedShops onChange={linkShops} assignedShops={feedback.isWaiting ? null : localeLinkedShops} />
      </div>
    </>
  )
}
