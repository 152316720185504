import { request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/fields`;

const service = () => {
  return {
    findAll: (pageInfo: PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    findOne: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'get'),
    create: (pendingFieldDefinition: PendingFieldDefinition) => request(`${baseUrl}`, 'post', pendingFieldDefinition),
    update: (fieldDefinition: FieldDefinition) => request(`${baseUrl}/${fieldDefinition.id}`, 'patch', fieldDefinition),
    remove: (fieldDefinition: ObjectWithId) => request(`${baseUrl}/${fieldDefinition.id}`, 'delete'),
    attachShops: (fieldDefinition: FieldDefinition, shops: Shop[]) => request(`${baseUrl}/${fieldDefinition.id}/shops`, 'post', { shops }),
  };
};

export default service;
