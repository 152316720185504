import { useLayoutEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { selectUserProfile } from '../../features/authentication/authenticationSlice';
import { ProfileDropdown } from "./ProfileDropdown";
import { userRoleRoutes } from '../../features/users/utils';

interface NavItem {
  id: string,
  label: string,
  to: string,
  active: boolean,
};

const NavLink = (props:NavItem) => (
  <li>
    <Link className={`text-white ${props.active ? 'font-bold' : ''}`} to={props.to}><Trans i18nKey={props.label} /></Link>
  </li>
);

export const NavBar = () => {
  const userProfile = useAppSelector(selectUserProfile);
  const location = useLocation();

  const navItems = useMemo(() => {
    if (!userProfile?.role) {
      return [];
    }
    return  (userRoleRoutes(userProfile.role))
    .map(curr => ({
      id: curr,
      to: `/${curr}`,
      label: `${curr}.nav`,
      active: `/${curr}` === location.pathname || location.pathname.indexOf(`/${curr}/`) === 0,
    }));
  }, [userProfile, location]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname])

  const showNav = useMemo(() => {
    return userProfile && userProfile.fistLogin === false && navItems.length > 0;
  }, [userProfile, navItems]);

  return (
    <header className='c-header bg-primary-light'>
      <div className='container mx-auto flex items-center gap-x-12'>
        <Link to="/register"><img src='/logo.svg' className='py-5' alt="betcenter logo"/></Link>
        {/** NAV **/}
        {showNav && (
        <nav>
          <ul className="flex items-center gap-x-4">
            {navItems.map(navItem => (<NavLink key={navItem.id} {...navItem} />))}
          </ul>
        </nav>
        )}

        {/** PROFILE **/}
        {showNav && (
          <div className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <ProfileDropdown />
          </div>
        )}
      </div>
    </header>
  )
}
