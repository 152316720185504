import { useState, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate, useLocation} from "react-router-dom";
import { login } from './actions';
import { selectAuthenticated, selectReload, selectFeedback, selectUserProfile } from './authenticationSlice';
import { Feedback } from './components/Feedback';
import { Form } from './components/Form';
import { FirstLogin } from './components/FirstLogin';

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const locationState = location.state as {
    from: { pathname: string }
  };

  const [localeUser, setLocaleUser] = useState<UserCredentials>({username: '', password: ''});

  const authenticated = useAppSelector(selectAuthenticated);
  const userProfile = useAppSelector(selectUserProfile);

  const reload = useAppSelector(selectReload);
  const feedback = useAppSelector(selectFeedback);

  useEffect(() => {
    // On logout the accessToken will be reset and 'reload' will be set to true
    // to force an app reload to clear the store.
    if (reload === true) {
      window.location.reload();
    }
  }, [reload]);

  useEffect(() => {
    if (authenticated === true && userProfile && !userProfile.fistLogin) {
      // Redirect to where we come from before a redirect to the login or use
      // 'register' as default route.
      navigate(locationState && locationState?.from?.pathname !== '/logout' ?
        locationState.from.pathname : '/register', { replace: true })
    }
  }, [navigate, authenticated, locationState, userProfile]);

  const onSubmit = useCallback(() => {
    dispatch(login(localeUser));
    return false;
  }, [dispatch, localeUser]);

  const onChange = useCallback((key: string, value: string) => {
    setLocaleUser({ ...localeUser, [key]: value });
  }, [localeUser]);

  return (
    <div className='flex items-center h-full'>
      <div className='o-box bg-white p-8 w-full max-w-[480px] mx-auto'>
        {!userProfile &&
          <Form onSubmit={onSubmit} onChange={onChange} userCredentials={localeUser} disabled={feedback.isWaiting} />
        }
        {(userProfile && userProfile.fistLogin) && (
          <FirstLogin userProfile={userProfile} userCredentials={localeUser} disabled={feedback.isWaiting} />
        )}
        <Feedback />
      </div>
    </div>
  )
}

export default Login;
