import { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ToolBar } from '../../app/components/ToolBar';
import { ToolBarSearch } from '../../app/components/ToolBarSearch';
import {LinkButton} from '../../app/components/LinkButton';
import {findAll} from './actions';
import {selectShops} from './shopsSlice';

interface ActionsProps {
  onSearch: Function;
};
const Actions = (props: ActionsProps) => (
  <>
    <ToolBarSearch placeholder="shops.search" onChange={props.onSearch} />
    <LinkButton to="/shops/add" label="shops.add" />
  </>
);

export function Overview() {
  const shops = useAppSelector(selectShops);
  const dispatch = useAppDispatch();
  const [localShops, setLocalShops] = useState<Shop[]>([]);

  useEffect(() => {
    dispatch(findAll());
  }, [dispatch]);

  // Filter local shops
  const filterShops = useCallback((value:string) => {
    if (!shops) { return; }
    if (value && value.length < 2) {
      return setLocalShops(shops);
    }
    setLocalShops(shops.filter((item) => item.name.toLowerCase().includes(value)));
  }, [shops]);

  // Copy shops for local use
  useEffect(() => {
    if (!shops) { return; }
    setLocalShops(shops);
  }, [shops]);

  return (<>
    <ToolBar title='shops.title' actions={<Actions onSearch={filterShops}/>} />
    <div className='o-table'>
      <table>
        <thead>
          <tr>
            <td><Trans i18nKey="name" /></td>
            <td><Trans i18nKey="assigned users" /></td>
            <td><Trans i18nKey="assigned custom shops" /></td>
          </tr>
        </thead>
        <tbody>
          {localShops.map(c =>
            <tr className='o-table-row' key={c.id}>
              <td><Link to={`/shops/edit/${c.id}`}>{c.name}</Link></td>
              <td>{c.linkedUserCount || '0'}</td>
              <td>{c.linkedFieldCount || '0'}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </>
  )
}
