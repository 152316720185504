import { errorToast } from '../features/toast/toastSlice';

export function handleServiceError(err:any, dispatch?:any) {
  if (err.message && dispatch) {
    if (Array.isArray(err.message)) {
      err.message.map((c:string) => dispatch(errorToast(c)));
    } else if (typeof err.message === 'string') {
      dispatch(errorToast(err.message));
   }
  }
  return err;
}

export function paging(pageInfo?:PageInfo) {
  return {
    page: 1, // default paging value
    // @TODO lower page limit after paging is implemented.
    limit: 999, // default paging value
    ...pageInfo || {},
  }
}

export function messages(message:string[]|string) {
  if (Array.isArray(message)) {
    return message;
  }
  if (typeof message === 'string' && message.length){
    return [message];
  }
  return [];
}
