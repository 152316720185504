import { InputNumber, Input } from 'antd';
import { formatAmount } from '../utils';

const { TextArea } = Input;

interface FieldFragmentProps {
  values?: Map<string, number>;
  textValues?: Map<string, string>;
  fieldFragment: FieldFragment;
  onChange: Function;
  canEdit: boolean;
}

interface FieldFragmentNumberProps extends FieldFragmentProps{
  defaultValue: number;
  min?: number;
}

interface FieldFragmentTextProps extends FieldFragmentProps {
  defaultValue: string;
}

export const FieldFragmentNumber = (props: FieldFragmentNumberProps ) => (
  <div>
    <label>{props.fieldFragment.name.current}</label>
    {props.fieldFragment.description && <div className="text-gray-500 text-sm">{props.fieldFragment.description.current}</div>}
    {props.canEdit &&
    <InputNumber
      addonAfter="€"
      defaultValue={props.defaultValue || 0}
      precision={2}
      min={typeof props.min === 'number' ? props.min : 0}
      onChange={(value) => props.onChange(props.fieldFragment, value)}
      onBlur={(evt) => {if(evt.target.value.trim() === '') { props.onChange(props.fieldFragment, 0)}}}
    />}
    {!props.canEdit &&
      <div className="flex gap-2">
        <span>{formatAmount(props.defaultValue)}</span>
        <span>€</span>
      </div>
    }
  </div>
);

export const FieldFragmentText = (props: FieldFragmentTextProps ) => (
  <div>
    <label>{props.fieldFragment.name.current}</label>
    {props.fieldFragment.description && <div className="text-gray-500 text-sm">{props.fieldFragment.description.current}</div>}
    {props.canEdit &&
    <TextArea
      defaultValue={props.defaultValue}
      onChange={(event) => props.onChange(props.fieldFragment, event.currentTarget.value)}
      showCount={true}
      rows={6}
      maxLength={512}
    />}
    {!props.canEdit &&
      <div>
        {props.defaultValue}
      </div>
    }
  </div>
);

export const FieldFragment = (props: FieldFragmentProps ) => {
  if (props.fieldFragment.inputType === 'number') {
    return <FieldFragmentNumber {...props} defaultValue={(props.values && props.values.get(props.fieldFragment.id)) || 0} />;
  } else if (props.fieldFragment.inputType === 'text') {
    return <FieldFragmentText {...props} defaultValue={(props.textValues && props.textValues.get(props.fieldFragment.id)) || ''} />;
  }
  return null;
}

interface FieldDefinitionProps {
  field: FieldDefinition;
  values?: Map<string, number>;
  textValues?: Map<string, string>;
  onChange: Function;
  canEdit: boolean;
}

export const FieldDefinition = (props: FieldDefinitionProps ) => {
  return (
    <div className="p-6 bg-white">
      <h2>{props.field.name.current}</h2>
      <div className="grid grid-cols-2 gap-2">
        {props.field && props.field.fieldFragments
          .filter(item => item.operation !== 'daily-total')
          .map((item) =>
           <FieldFragment key={item.id} fieldFragment={item} {...props} /> )}
      </div>
    </div>
  );
};

