import {useState, useEffect, useCallback} from 'react';
import difference from 'lodash/difference';
import { getUsers } from '../../features/users/actions';
import { selectUsers } from '../../features/users/usersSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SimpleTable } from './SimpleTable';
import {userDisplayName} from '../../features/users/utils';

interface Props  {
  assigendUsers: User[] | null;
  onChange: Function;
  children?: React.ReactNode;
}

const usersAsTableData = (users:User[]) =>
  users.map(user => ({id: user.id, name: userDisplayName(user)}));

export const LinkedUsers: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const { onChange } = props;

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (!users) { return; }
    if (props.assigendUsers) {
      const availableUserIds = difference(users.map(c => c.id), props.assigendUsers.map(c => c.id));
      setAvailableUsers(users.filter(c => availableUserIds.includes(c.id)) );
    } else {
      setAvailableUsers(users);
    }
  }, [users, props.assigendUsers]);

  const onUpdate = useCallback((selectedIds:string[], assign:boolean) => {
    if (!users) { return; }
    onChange(users.filter(user => selectedIds.indexOf(user.id) !== -1), assign);
  }, [users, onChange])

  return (
    <div className='o-box bg-white p-8 w-full'>
      {/** ASSIGNED USERS */}
      <SimpleTable
      title="Assigned users"
      actionName="Unassign User"
      header="User"
      dataSource={usersAsTableData(props.assigendUsers || [])}
      onChange={(selectedRowKeys:string[]) => {onUpdate(selectedRowKeys, false)}}
      />
      {/** AVAILABLE USERS */}
      <SimpleTable
      title="Other users"
      actionName="Assign User"
      header="User"
      dataSource={usersAsTableData(availableUsers)}
      onChange={(selectedRowKeys:string[]) => {onUpdate(selectedRowKeys, true)}}
      />
      {props.children}
    </div>
  )

}

