import { createSlice } from '@reduxjs/toolkit';
import {login, logout, me, updateUserProfile, updateUserPassword } from './actions';
import { RootState } from '../../app/store';
import { messages } from '../../api/utils';
import {changeLanguage} from 'i18next';

interface authenticationState {
  authenticated: boolean;
  error: boolean | null;
  isWaiting: boolean;
  message: string;
  user: UserProfile | null;
  accessToken: string | null;
  reload: boolean; // if 'true' will cause the '/login' route to reload the app
}

const initialState: authenticationState = {
  authenticated: false,
  error: false,
  isWaiting: false,
  message: '',
  user: null,
  accessToken: null,
  reload: false,
}

function pending(state:any, message:string = '') {
  state.error = null;
  state.isWaiting = true;
  state.message = message;
}

function fulfilled(state:any, message:string = '') {
  state.error = false;
  state.isWaiting = false;
  state.message = message;
}

function rejected(state:any, message:string = '') {
  state.error = true;
  state.isWaiting = false;
  state.message = message;
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    reset: state => {
      state.message = '';
    },
  },
  extraReducers: (builder) => builder
    //
    // LOGIN
    //
    .addCase(login.pending, (state) => {
      pending(state);
      state.authenticated = false;
      state.user = null;
      state.accessToken = null;
    })
    .addCase(login.fulfilled, (state, action) => {
      const { user, accessToken } = action.payload;
      fulfilled(state);
      state.authenticated = true;
      state.user = user;
      state.accessToken = accessToken;
    })
    .addCase(login.rejected, (state, action) => {
      rejected(state, action.payload as string);
      state.authenticated = false;
      state.user = null;
    })
    //
    // LOGOUT
    //
    .addCase(logout.pending, (state) => {
      pending(state);
      state.user = null;
      state.accessToken = null;
    })
    .addCase(logout.fulfilled, (state) => {
      fulfilled(state);
      state.authenticated = false;
      state.reload = true;
    })
    .addCase(logout.rejected, (state, action) => {
      rejected(state, action.payload as string)
    })
    //
    // CHECK IF ACCESSTOKEN IS STILL VALID
    //
    .addCase(me.pending, (state) => {
      state.isWaiting = true;
    })
    .addCase(me.fulfilled, (state, action) => {
      const { user, accessToken } = action.payload;
      state.isWaiting = false;
      state.message = '';
      state.error = false;
      state.isWaiting = false;
      state.authenticated = true;
      state.user = user;
      state.accessToken = accessToken;
      // Set app language based on user profile
      if (user.language) {
        changeLanguage(user.language);
      }
    })
    .addCase(me.rejected, (state) => {
      state.isWaiting = false;
    })
    // UPDATE USER PROFILE (LANGUAGE)
    .addCase(updateUserProfile.pending, (state) => {
      pending(state);
    })
    .addCase(updateUserProfile.fulfilled, (state, action) => {
      const { user } = action.payload;
      if (user && user.id === state?.user?.id) {
        state.user = user;
      }
      fulfilled(state);
    })
    .addCase(updateUserProfile.rejected, (state, action) => {
      rejected(state, action.payload as string)
    })
    .addCase(updateUserPassword.fulfilled, (state, action) => {
      const { user } = action.payload;
      if (user && user.id === state?.user?.id) {
        state.user = user;
      }
    })
    //
    // DEFAULT
    //
    .addDefaultCase(() => {}),
});

// ACTIONS
export const { reset } = authenticationSlice.actions;

// SELECTORS
export const selectUserProfile = (state: RootState) => state.authentication.user;
export const selectAuthenticated = (state: RootState) => state.authentication.authenticated;
export const selectReload = (state: RootState) => state.authentication.reload;
export const selectFeedback = (state: RootState): Feedback => ({
  isWaiting: state.authentication.isWaiting,
  message: messages(state.authentication.message),
  error: state.authentication.error,
});

export default authenticationSlice.reducer;
