import { createSlice } from '@reduxjs/toolkit';
import { getResponseError } from '../../api';
import { messages } from '../../api/utils';
import { RootState } from '../../app/store';
import { createUser, getUser, getUsers, update, attachShops, reset } from './actions';

interface usersState {
  error: boolean | null;
  isWaiting: boolean;
  items: User[] | null;
  current: User | null;
  message: string;
}

const initialState: usersState = {
  error: false,
  isWaiting: false,
  items: null,
  current: null,
  message: '',
}

function pending(state:any, message:string = '') {
  state.error = null;
  state.isWaiting = true;
  state.message = message;
}

function fulfilled(state:any, message:string = '') {
  state.error = false;
  state.isWaiting = false;
  state.message = message;
}

function rejected(state:any, message:string = '') {
  state.error = true;
  state.isWaiting = false;
  state.message = message;
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET ALL USERS
    .addCase(getUsers.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.current = null;
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items;
      state.message = '';
    })
    .addCase(getUsers.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = getResponseError(action);
    })
    // GET SINGLE USER
    .addCase(getUser.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(getUser.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = '';
    })
    .addCase(getUser.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // CREATE USER
    .addCase(createUser.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'Adding new user';
    })
    .addCase(createUser.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De medewerker is aangemaakt.';
      state.message = 'New user saved';
    })
    .addCase(createUser.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // UPDATE USER
    .addCase(update.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'Saving user';
    })
    .addCase(update.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'User saved';
    })
    .addCase(update.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // ATTACH Shops
    .addCase(attachShops.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'Saving user';
    })
    .addCase(attachShops.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'User saved';
    })
    .addCase(attachShops.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // RESET
    .addCase(reset.pending, (state) => {
      pending(state);
    })
    .addCase(reset.fulfilled, (state) => {
      fulfilled(state, 'User reset')
    })
    .addCase(reset.rejected, (state, action) => {
      rejected(state, getResponseError(action));
    })
    .addDefaultCase(() => { }),
});

// SELECTORS
export const selectUsers = (state: RootState) => state.users.items;
export const selectUser = (state: RootState) => state.users.current;
export const selectFeedback = (state: RootState): Feedback => ({
  isWaiting: state.users.isWaiting,
  message: messages(state.users.message),
  error: state.users.error,
});

export default usersSlice.reducer;
